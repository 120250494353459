<template>
    <div class="Container">
        <p class="title">个人用户模式和商户模式同时开通时，支付模式优先匹配商户模式</p>
        <el-tabs v-model="activeName">
            <el-tab-pane label="商户模式" name="1">
                <business></business>
            </el-tab-pane>
            <el-tab-pane label="个人用户模式" name="2">
                <person></person>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import business from './components/business'
import person from './components/person'
export default {
    components: {
        business,
        person,
    },
    data() {
        return {
            activeName: '1',
        }
    },
    created() {
        this.activeName = this.$route.params.type||'1'
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.Container {
    padding: 20px;

    .title {
        height: 21px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        padding-bottom: 30px;
    }

    ::v-deep .el-tabs__nav-wrap {
        padding: 0px;
    }
}
</style>