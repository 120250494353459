<template>
    <div class="container">
        <!-- 未开通 -->
        <div v-if="!info.status">
            <button class="open-btn" @click="handle(1)">立即开通</button>
        </div>
        <!-- 审核中 -->
        <div class="verifyBox columnBox" v-if="info.status == 1">
            <i class="verify"></i>
            <p class="content">资料提交成功，请等待管理员审核！预计3个工作日内审核完毕</p>
        </div>
        <!-- 已通过 -->
        <div class="successBox columnBox" v-if="info.status == 2">
            <i class="success"></i>
            <p class="content gray">开通成功</p>
            <p class="content">修改资料需重新提交审核</p>
            <div>
                <button class="open-btn gray-btn" @click="handle(2)">取消开通</button>
                <button class="open-btn" @click="handle(3)">修改资料</button>
            </div>
        </div>
        <!-- 未通过 -->
        <div class="notPassBox columnBox" v-if="info.status == 3">
            <i class="notPass"></i>
            <p class="content gray">审核不通过</p>
            <p class="content">{{ info.error }}</p>
            <div>
                <button class="open-btn" @click="handle(4)">再次开通</button>
            </div>
        </div>
        <p class="content">1.开通个人用户收款账户后，机构订单结算金额进入叮叮可提现账户，订单结算周期为T+7,您可随时提现账户余额至绑定的银行卡</p>
        <p class="content">2.收款额度：正常情况下，每日收款额度为1万元，如果交易表现良好，可调整到10-20万元/日，但如交易异常，系统将自动关闭代收款模式</p>
        <p class="content">3.开通即默认同意<a class="green cp" @click="toProtocol(4)">《叮叮开课代收款项代收服务协议》</a></p>
        <p class="content">4.提现收取3%-10%手续费，详情参考<a class="green cp" @click="toProtocol(4)">《叮叮开课代收款项代收服务协议》</a></p>
        <p class="content">5.提交提现申请后款项在7个工作日内到达您选择的银行账号</p>
    </div>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            info: {}
        }
    },
    created() {
        this.getActivation()
    },
    methods: {
        handle(type) {
            switch (type) {
                case 1:
                    // 微信支付个人用户
                    this.$router.push('/setting/paymentSetting/individual')
                    break;
                case 2:
                    // 取消开通
                    this.$confirm(
                        '取消后，学员在网校内支付将没有微信支付的选项',
                        '取消开通',
                        {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            confirmButtonClass: 'livebroadcastSettingsbtcolor',
                            cancelButtonClass: 'livebroadcastSettingsCancelButtonClass',
                        }
                    ).then(() => {
                        this.cancelInformation()
                    }).catch((err) => {
                        console.log(err)
                    })
                    break;
                case 3:
                case 4:
                    // 修改资料
                    this.$router.push({
                        // path:'/setting/paymentSetting/individual',
                        name: 'individual',
                        params: {
                            edit: true,
                        }
                    })
                    break;
            }
        },
        getActivation() {
            this.$http({
                name: 'getActivation',
                url: '/Information/getActivation',
                data: {},
                callback: ({ data }) => {
                    console.log(data);
                    this.info = Object.assign(data, {
                        // status:2
                    })
                },
            })
        },
        async cancelInformation() {
            await this.$http({
                name: 'cancelInformation',
                url: '/Information/cancel',
                data: {},
                callback:()=>{
                    this.getActivation()
                    this.$root.prompt('取消开通成功')
                }
            })
        },
        /**
         * @name: toProtocol
         * @test: test font
         * @msg: 跳转协议页面
         * @param {none}
         * @return: none
         */
        toProtocol(val) {
            const { href } = this.$router.resolve({
                path: '/protocol',
                query: {
                    type: val,
                },
            })
            this.protocolHref = href
            this.$store.dispatch('open', this.protocolHref)
        },
    }
}
</script>

<style scoped lang="scss">
@mixin center {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 30px 0;

    .content {
        margin: 0px;
    }

    .open-btn {
        margin-top: 10px;
    }
}

.cp {
    cursor: pointer;
}
.container {

    .columnBox {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .content {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #666666;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        margin: 5px auto 0 0px;

        .green {
            color: #0AA29B;
        }
    }

    .open-btn {
        border: 0;
        width: 152px;
        height: 42px;
        font-size: 17px;
        text-align: center;
        background: #0aa29b;
        color: #ffffff;
        border-radius: 3px;
        cursor: pointer;
        margin: 30px auto 22px 0px;

        &.disable {
            color: #666666;
            background: #e1e1e1;
        }
    }

    .gray-btn {
        background: #F0F0F0;
        border-radius: 3px;
        font-size: 17px;
        color: #A4A4A4;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        margin-right: 20px;
    }

    .verifyBox {
        @include center;

        .verify {
            height: 102px;
            width: 120px;
            background: url('../../../assets/img/1.4.46/verify.svg') no-repeat center center;
        }
        .content {
            font-weight: bold;
            font-size: 14px;
            color: #333333;
            line-height: 23px;
            text-align: center;
            font-style: normal;
        }
    }

    .successBox {
        @include center;

        .success {
            height: 100px;
            width: 128px;
            background: url('../../../assets/img/1.4.46/success.svg') no-repeat center center;
        }

        .gray {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
        }
    }

    .notPassBox {
        @include center;

        .notPass {
            height: 100px;
            width: 149px;
            background: url('../../../assets/img/1.4.46/not_pass.svg') no-repeat center center;
        }

        .gray {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
        }
    }

}
</style>