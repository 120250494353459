<template>
    <div class="container">
        <button class="open-btn disable" v-if="weChatPayObj.wechat_type == 2" @click="jump(1)">已开通</button>
        <button class="open-btn" @click="jump(1)" v-else>立即开通</button>
        <p class="content">开通商户号模式后，机构可以完成自主收款，订单金额将结算至商户账户余额，开通必须先关联公众号</p>
        <div class="t2" v-if="weChatPayObj.wechat_type == 2">
            <p>
                微信支付授权目录：
                <span>{{ weChatPayObj.domain }}</span>
            </p>
            <p>
                微信商户号：
                <el-input v-if="isedit" maxlength="50" show-word-limit v-model="weChatPayObj.appsecret"
                    placeholder="请输入微信商户号"></el-input>
                <span v-else>
                    {{ weChatPayObj.appsecret }}
                </span>
            </p>
            <p>
                微信商户密钥：
                <el-input v-if="isedit" maxlength="50" show-word-limit v-model="weChatPayObj.sub_key"
                    placeholder="请输入微信商户密钥"></el-input>
                <span v-else>
                    {{ weChatPayObj.sub_key }}
                </span>
            </p>
            <el-button type="primary" @click="isedit = true" v-if="!isedit">
                编辑
            </el-button>
            <el-button type="primary " @click="save" v-else>保存</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            isedit: false,
            weChatPayObj: {},
        }
    },
    created() {
        this.getWeChatPay()
    },
    methods: {
        // 获取微信支付的数据
        async getWeChatPay() {
            try {
                const { data } = await this.$http({
                    url: '/user/getWeChatPay',
                    data: {},
                })
                this.weChatPayObj = data
            } catch (e) {
                console.alert(e)
            }
        },
        // 保存微信支付编辑的数据
        async save() {
            try {
                await this.$http({
                    url: '/user/saveSub',
                    data: this.weChatPayObj,
                })
                this.isedit = false
                this.getWeChatPay()
            } catch (e) {
                console.alert(e)
            }
        },
        jump(type) {
            switch (type) {
                case 1:
                    // 微信支付
                    this.$router.push('/setting/wechat')
                    break;
            }
        }
    }
}
</script>

<style scoped lang="scss">
.container {

    .open-btn {
        border: 0;
        width: 152px;
        height: 42px;
        font-size: 17px;
        text-align: center;
        background: #0aa29b;
        color: #ffffff;
        border-radius: 3px;
        cursor: pointer;
        margin: 30px auto 22px 0px;

        &.disable {
            color: #666666;
            background: #e1e1e1;
        }
    }

    .content {
        height: 16px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #666666;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        margin: 0 auto 0 0px;
    }

    .t2 {
        font-size: 13px;
        color: #333333;
        line-height: 17px;
        margin-top: 20px;

        p {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            .el-input {
                flex: 1;
            }
        }
    }
}
</style>